import {LOG} from "../tools";

const isLocal = (process.env.ISLOCAL==1)
const urlApi = isLocal?'/data/fake':'https://voice-election-public.s3.fr-par.scw.cloud/data'

export function getData(nameFile,idDate) {

    // TRY TO USE GZ FILE :
    //https://stackoverflow.com/questions/68254992/decompress-a-json-file-in-reactjs

  return fetch(`${urlApi}/${nameFile}?${idDate}`,{
      headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    })
    .then(response => {
        return response.json()
    })
    .catch(error => {
      console.error(error);
    });
}

export function getUrlImgWordCloudTopic(tagId,lastUpdateId){
    return `${urlApi}/topics/p/${tagId}/wc.jpg?${lastUpdateId}`
}

export function getUrlImgWordCloudHier(lastUpdateId){
    return `${urlApi}/top/wc/hier.jpg?${lastUpdateId}`
}


export function getDataPageLoad(namePage){
    const idDate = new Date().valueOf();
    return getData(`page/${namePage}.json`,idDate)
}

export function getDataHotNews(lastUpdateId) {
        return getData("dHotNews.json",lastUpdateId)
    //return getData("test.json",idDate)
}
export function getDataTop(lastUpdateId) {
        return getData("top/dTop.json",lastUpdateId)
    //return getData("test.json",idDate)
}

export function getDataTopVocabulary(timeTop,lastUpdateId) {
        return getData(`top/dTop_${timeTop}_v.json`,lastUpdateId)
}
export function getDataTopTweets(timeTop,lastUpdateId) {
        return getData(`top/dTop_${timeTop}_t.json`,lastUpdateId)
}

export function getDataTopFullVocabulary(name_id,lastUpdateId) {
        return getData( `top/v_${name_id}.json`,lastUpdateId)
}

export function getDataPersonsSummary(name_id,lastUpdateId) {
        return getData(`persons/dSum_${name_id}.json`,lastUpdateId)
}

export function getDataPersonsEvol(name_id,lastUpdateId) {
        return getData(`persons/dEvol_${name_id}.json`,lastUpdateId)
}

export function getDataPersonsVocabs(name_id,lastUpdateId) {
        return getData(`persons/dVocabs_${name_id}.json`,lastUpdateId)
}

export function getDataTagTopics(name_id,lastUpdateId) {
        return getData(`topics/dTopics_${name_id}.json`,lastUpdateId)
}
export function getDataSummaryVocabs(name_id,lastUpdateId) {
        return getData(`topics/Summary_${name_id}.json`,lastUpdateId)
}
export function getDataSummaryVocabsCard(tag_id,lastUpdateId) {
        return getData(`topics/p/${tag_id}/c.json`,lastUpdateId)
}

export function getDataHotTag(tagId,lastUpdateId) {
    return getData(`tag/${tagId}/h.json`,lastUpdateId)
}
export function getDataContentSummaryTag(tagId,lastUpdateId) {
    return getData(`tag/${tagId}/content_summary.json`,lastUpdateId)
}
export function getListDataContentSummaryTag(listTagId,lastUpdateId){

    return Promise.all(listTagId.map((t_id)=>getDataContentSummaryTag(t_id,lastUpdateId).then(value => {
        return { ...value, id: t_id}
    })))
}


export function getDataPageTag(tagId,lastUpdateId) {
    //const idDate = new Date().valueOf();
    return getData(`tag/${tagId}/p.json`,lastUpdateId)
}

export function getDataGlobalInfo(lastUpdateId) {
        return getData("dGlobalInfo.json",lastUpdateId)


}

